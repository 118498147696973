import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewSearchTextField } from 'components/shared/searchTextField/NewSearchTextField';
import TotalAmount from 'components/shared/totalAmount/NewTotalAmount';


export const FilterBarContainer = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
row-gap: ${newTypography.unit * 5}px;
flex: 1;
min-height: 131px;
gap: 16px;
border-radius: 8px;
border: 6px 0px 0px 0px;
opacity: 0px;
background: #F5F5F5;
border-top: 6px solid #FFBC0D;
`;

export const NewStyledFilterBarSelect = styled(NewSelectbox)`
margin: ${newTypography.unit * 1}px ${newTypography.unit * 5}px;
opacity: 0px;
[data-automation-id="chip"] {
      background-color: #FFBC0D !important;
      color:${newGlobalTheme.colors.text.primary} 
  }

  input {
    flex: 1 1 auto;
  }
}
` as unknown as typeof NewSelectbox;

export const ImageNewSearchTextField = styled(NewSearchTextField)`
 background:rgb(247, 247, 247);
padding-top: 18px;
width: 385.67px;
height:59px;
gap: 8px;
opacity: 0px;

`;
const StyledTotalAmount = styled(TotalAmount)`
  align-self: flex-end;
`;
export const StyledTotalAmountWithGrid = styled(StyledTotalAmount)`
position:relative;
width: 91px;
height: 23px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #292929;
margin-bottom:35px;
padding-left:5px;
width:100px;
`;