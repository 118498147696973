import React, { useState } from 'react';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { useSelector } from 'react-redux';
import { store } from 'app/store';
import { FormMode, OrderDirection } from 'utils/types';
import { Modals, openModal } from 'app/slices/modals';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import {  StyledLoader } from 'pages/shared/shared.style';
import { vceImagesPage } from 'app/genericSlices/images';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import useQueryInterval from 'hooks/use-query-polling';
import { marketConfig } from 'app/slices/config';
import { ActionButtonsContainer, ImageNewStyledHeader, ImagePageContainer, ImagesList, ImagesListWrapper, StyledInputFiles, StyledPlus } from './Images.style';
import { ImageProps, ImageSource } from './Images.consts';
import { archiveImageClicked, readImages, unarchiveImageClicked } from './images.utils';
import ImagesFilterBar from './components/imagesFilterBar/ImagesFilterBar';
import ImageItem from './components/imageItem/ImageItem';
import { imagesGqls } from './Images.gqls';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { Loader } from 'components/shared/loader';
import AddIcon from '@mui/icons-material/Add';

const Images = () => {
  const { config } = useSelector(marketConfig);
  const { filters } = useSelector(vceImagesPage.imagesState);
  const [isImageArchieving, setIsImageArchieving] = useState(false);
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(imagesGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: vceImagesPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
        imageSource: ImageSource.VCE,
      },
    },
  });
  const {
    getImages: { items: images, total },
  } = data || { getImages: { items: undefined } };
  useToastError(error, 'Error loading images');
  useQueryInterval(600000, startPolling, stopPolling);

  const handleFiles = async (files: FileList) => {
    const totalImages = files.length;
    const uploadedImages = await readImages(files, config.bulkImagesUploadLimit);
    if (uploadedImages?.length) {
      const removedImagesCount =
        totalImages > config.bulkImagesUploadLimit ? totalImages - config.bulkImagesUploadLimit : 0;

      store.dispatch(openModal({ modal: Modals.ImagesModal, props: { uploadedImages, removedImagesCount } }));
    }
  };

  const viewImageClicked = (image: ImageProps) =>
    store.dispatch(openModal({ modal: Modals.ImageModal, props: { mode: FormMode.View, image } }));

  const editImageClicked = (image: ImageProps) =>
    store.dispatch(openModal({ modal: Modals.ImageModal, props: { mode: FormMode.Edit, image } }));

  const fetchNextImages = async () => {
    if (images.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: vceImagesPage.getFilters(filters),
            offset: images.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
            imageSource: ImageSource.VCE,
          },
        },
      });
    }
  };

  return (
    <ImagePageContainer>
      <ImageNewStyledHeader data-automation-id="header">
        <NewTextPageTitle text={tabName.Images} />
        <ActionButtonsContainer data-automation-id="actions">
          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
            <div data-title="Add">
              <StyledInputFiles handleFiles={handleFiles} filesType="image/*">
                <AddIcon style={{ fill: 'black' }} data-automation-id="newPlus"/>
              </StyledInputFiles>
            </div>
          </RoleGuard>
        </ActionButtonsContainer>
        <ImagesFilterBar imageSource={ImageSource.VCE} total={total}/>

        
      </ImageNewStyledHeader>
      {!images ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          {Boolean(images.length) && (
            <ImagesListWrapper id="images-scroll">
              {isImageArchieving && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              <ImagesList
                scrollableTarget="images-scroll"
                dataLength={images.length}
                next={fetchNextImages}
                hasMore={images.length < total}
                loader={<StyledLoader size={LoaderSize.Large} />}
              >
                {images.map((image: ImageProps, index: number) => (
                  <ImageItem
                    key={`${image.id}_${index}`}
                    image={image}
                    viewImage={viewImageClicked}
                    editImage={editImageClicked}
                    archiveImage={(image1) => archiveImageClicked(image1, setIsImageArchieving)}
                    unarchiveImage={unarchiveImageClicked}
                  />
                ))}
              </ImagesList>
              <Tooltip id={'image-lock-icon'} onHover content="Locked for changes" place="bottom" />
            </ImagesListWrapper>
          )}
        </>
      )}
    </ImagePageContainer>
  );
};

export default Images;
