import React, { useMemo, useState } from 'react';
import { ApprovalStatus } from 'utils/types';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { Tags } from 'components/shared/tags/Tags';
import { ImageItemProps } from './ImageItem.consts';
import {
  Actions,
  Container,
  ImageBackdrop,
  ImageName,
  ImageWrapper,
  StyledButtonText,
  StyledIcon,
  StyledImage,
  StyledView,
  StyledArchiveIcon,
  ImageId,
  ImgContainer,
  ImageNameWrapper,
  ImageViewDetailContainer,
  ImageStatusContainer,
  StyledViewDetailText,
  StyledStatusLabel,
  ImageStatusWrapper,
  StyledSeprator,
} from './ImageItem.style';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';
import { ImageSource } from '../../Images.consts';
import { handleKeyPress } from 'utils/keyPress';


const ImageItem = ({ image, className, viewImage, editImage, archiveImage, unarchiveImage }: ImageItemProps) => {
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const id = useMemo(() => Math.random().toString(8), [image.updatedAt]);

  const getRoles = (imageSource: ImageSource): UserRole[] => {
    if (imageSource === ImageSource.VCE) {
      return [UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]
    }
    return [UserRole.SysAdmin, UserRole.Admin];
  }

  return (
    <Container className={className} data-automation-id="image-container">
      <ImageWrapper
        onMouseEnter={() => setShowBackdrop(true)}
        onMouseLeave={() => setShowBackdrop(false)}
        onClick={() => viewImage(image)}
      >

        <ImgContainer>
          <StyledImage
            src={`${image.file}?${id}`}
            alt={image.name}
            data-automation-id="image"
            crossOrigin="anonymous"
          />
        </ImgContainer>
        {showBackdrop && (
          <ImageBackdrop data-automation-id="backdrop">
            <Actions data-automation-id="actions">
              {!image.isLocked && (
                <RoleGuard roles={getRoles(image.imageSource)}>
                  {image.isArchive ? (
                    <StyledButtonText
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        unarchiveImage(image);
                      }}
                      data-title="Unarchive"
                    >
                      <StyledArchiveIcon name="newUnarchive" />
                    </StyledButtonText>
                  ) : (
                    <>
                      <StyledButtonText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editImage(image);
                        }}
                        data-title="Edit"
                      >
                          <StyledIcon name="offerEdit" />
                      </StyledButtonText>
                      <StyledButtonText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          archiveImage(image);
                        }}
                        data-title="Archive"
                      >
                        <StyledArchiveIcon name="newArchive" />
                      </StyledButtonText>
                    </>
                  )}
                </RoleGuard>
              )}
            </Actions>
            <Tags id={image.id} tags={image.tags} limit={4} />
          </ImageBackdrop>
        )}
      </ImageWrapper>
      <StyledSeprator></StyledSeprator>
      <ImageNameWrapper>
        <ImageName title={image.name}>{image.name}</ImageName>
        {image.isLocked && <NewLockIcon dataFor="image-lock-icon" />}
      </ImageNameWrapper>
      <ImageStatusWrapper isArchive={image.isArchive}>{
        image.isArchive &&
        <ImageStatusContainer tabIndex={0}>
          <StyledStatusLabel type={ApprovalStatus.Archived} />
        </ImageStatusContainer>
      }
        <ImageViewDetailContainer tabIndex={0} onKeyDown={(event) => handleKeyPress(event, () => viewImage(image))}  >
          <StyledViewDetailText onClick={() => viewImage(image)}  >
            View Details
          </StyledViewDetailText>
        </ImageViewDetailContainer>
      </ImageStatusWrapper>
    </Container>
  );
};

export default ImageItem;
