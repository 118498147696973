import styled from '@emotion/styled/macro';
import typography, { newTypography } from 'styles/typography';
import TermConditionItem from './components/termConditionItem/TermConditionItem';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import TotalAmount from 'components/shared/totalAmount/TotalAmount';
import { ThemeProps } from './TermsConditions.consts';
import NewOfferAddNewButton from 'pages/shared/addNewButton/OfferAddNewButton';
import { NewSearchTextField } from 'components/shared/searchTextField/NewSearchTextField';
import PageContainer from 'pages/shared/pageContainer/PageContainer';

export const TnCPageContainer = styled(PageContainer)`
  align-items: center;
`;

export const TermConditionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px ${typography.unit * 0}px ${typography.unit * 10}px ${typography.unit * 10}px;
  overflow-y: auto;
`;

export const StyledTermConditionItem = styled(TermConditionItem)`
  margin: 0 ${typography.unit * 10}px ${typography.unit * 8}px 0;
`;

export const TermConditionsListWrapper = styled.div`
  overflow: auto;
  width: 87%;
`;

export const TermsConditionsFilterBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: ${newTypography.unit * 5}px;
  flex: 1;
  min-height: 131px;
  padding: 36px 0px 0px 0px;
  gap: 16px;
  border-radius: 8px;
  border: 6px 0px 0px 0px;
  opacity: 0px;
  background: #f5f5f5;
  border-top: 6px solid #ffbc0d;
`;

export const TnCStyledFilterBarSelect = styled(NewSelectbox)`
margin: ${newTypography.unit * 1}px ${newTypography.unit * 5}px;
opacity: 0px;
[data-automation-id="chip"] {
      background-color: #FFBC0D !important;
      color:${newGlobalTheme.colors.text.primary} 
  }

  input {
    flex: 1 1 auto;
  }
}
` as unknown as typeof NewSelectbox;

export const TnCFilterRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const TnCFieldLabel = styled.label`
  width: 120px;
  min-height: 18px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-weight: 700;
  line-height: 15.17px;
  text-align: left;
  color: #292929;
  padding: 0px 0px 10px 20px;
`;

export const TnCTitleActionHeader = styled.div`
  display: flex;
  gap: ${newTypography.unit * 4}px;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledTotalAmount = styled(TotalAmount)`
  align-self: flex-end;
`;

export const StyledTotalAmountWithGrid = styled(StyledTotalAmount)`
  padding: ${newTypography.unit * 0.5}px ${newTypography.unit * 5}px;
  display: flex;
  gap: ${newTypography.unit * 2}px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -50px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
`;

export const TnCStyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 16.25}px ${typography.unit * 6}px 0 ${typography.unit * 6}px;
  width: 85%;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
`;

export const TnCAddNewButton = styled(NewOfferAddNewButton)`
  width: 36px;
  height: 36px;
  gap: 0px;
  border-radius: 5.02px;
  border: 1.26px 0px 0px 0px;
  opacity: 0px;
  padding: ${newTypography.unit * 2}px;
  border: 1.26px solid #c08b00;
  :hover {
    border: 1.26px solid #c08b00;
  }
`;

export const TncSearchTextField = styled(NewSearchTextField)`
  width: 386px;
  padding-top: 8px;
`;
