import React, { useEffect } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { OfferTemplates } from 'utils/types/offers';
import { useUrlFilters } from 'hooks/use-url-filters';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { store } from 'app/store';
import { termsConditionsPage } from 'app/genericSlices/termsConditions';
import { archiveStatusValues } from 'pages/settings/settings.const';
import useFeatureFlag from 'hooks/use-feature-flag';
import { useSelector } from 'react-redux';
import { Feature } from 'utils/types/features';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { TermFilterType } from '../../TermsConditions.consts';
import { marketConfig } from 'app/slices/config';
import {
  TermsConditionsFilterBarContainer,
  TnCFieldLabel,
  TnCFilterRow,
  TncSearchTextField,
  TnCStyledFilterBarSelect,
} from '../../TermsConditions.style';
import { NewSearchTextField } from 'components/shared/searchTextField/NewSearchTextField';

export const TermsConditionsFilterBar = () => {
  const ftArchiveTerms = useFeatureFlag(Feature.TermConditionArchive);
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      termsConditionsPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
    store.dispatch(termsConditionsPage.loadTermsPage());
  });
  const { filters } = useSelector(termsConditionsPage.termsConditionsState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[TermFilterType.SearchQuery]);

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);
  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => enableDigitalDownload || template.id !== '14',
  );

  return (
    <TermsConditionsFilterBarContainer tabIndex={-1}>
      {ftArchiveTerms && (
        <TnCFilterRow>
          <TnCFieldLabel tabIndex={0}>Status</TnCFieldLabel>
          <TnCStyledFilterBarSelect
            placeholder="Select"
            name="status"
            multiple
            items={archiveStatusValues as any[]}
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                TermFilterType.Archive,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={filters[TermFilterType.Archive]}
            maxItems={1}
            selectWidth={385.67}
          />
        </TnCFilterRow>
      )}
      <TnCFilterRow>
        <TnCFieldLabel tabIndex={0}>Offer Template</TnCFieldLabel>
        <TnCStyledFilterBarSelect
          placeholder="Select"
          name="offer-template"
          multiple
          items={Object.values(filteredOfferTemplates) as any[]}
          onChange={(selectedItems: any) => {
            urlFilters.filter(
              TermFilterType.OfferTemplate,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={filters[TermFilterType.OfferTemplate]}
          withSearch
          reset
          limit={filteredOfferTemplates.length}
          maxItems={1}
          selectWidth={385.67}
        />
      </TnCFilterRow>
      <TnCFilterRow>
        <TnCFieldLabel tabIndex={-1}></TnCFieldLabel>
        <TncSearchTextField
          key={`${JSON.stringify(filters[TermFilterType.SearchQuery])}_SearchQuery`}
          name="terms-search"
          caretPosition={caretPosition}
          value={filters[TermFilterType.SearchQuery] ?? ''}
          onChange={(e) => {
            urlFilters.filter(TermFilterType.SearchQuery, e.target.value);
            setCaretPosition(e);
          }}
        />
      </TnCFilterRow>
    </TermsConditionsFilterBarContainer>
  );
};
