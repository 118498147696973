import { Control, FieldErrors } from 'react-hook-form';
import { newAppTheme } from 'styles/themes/newGlobalTheme';

export interface SelectboxProps {
  items: any[];
  initialSelectedItems?: string[] | number[];
  label?: string;
  placeholder?: string;
  name?: string;
  valueField?: string;
  labelField?: string;
  disabled?: boolean;
  clearOnDisabled?: boolean;
  multiple?: boolean;
  withSearch?: boolean;
  labelIsHorizontal?: boolean;
  allowSelectAll?: boolean;
  allSelected?: boolean;
  withAmount?: boolean;
  reset?: boolean;
  defaultValue?: any;
  control?: Control;
  validation?: Record<string, unknown>;
  errors?: FieldErrors | string;
  limit?: number;
  maxItems?: number;
  onChange?: (newValue: any) => void;
  onCreateOption?: (s: string) => any;
  selectWidth?: number;
  theme?: newAppTheme;
  className?: string;
  getIsOptionDisabled?: (option: any) => boolean;
  version?: string;
  selectHeight?: number;
  containerGap?: number;
  customOption?: any;
  filters?: any;
}

export const offerFormVersion = 'offer-form';
export const campaignFormVersion = 'campaign-form';

