import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ButtonText } from 'components/shared/button';
import { ApprovalStatus, OrderDirection } from 'utils/types';
import { Language, LanguageRecord } from 'utils/languages';
import { OfferTemplates as OfferTemplatesTypes } from 'utils/types/offers';
import { marketConfig } from 'app/slices/config';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { Feature } from 'utils/types/features';
import { TermConditionItemProps } from './TermConditionItem.consts';
import {
  Container,
  Header,
  HeaderActions,
  HeaderContainer,
  NewStyledStatusLabel,
  OfferTemplates,
  StyledArchiveIcon,
  StyledButtonText,
  StyledDownloadButton,
  StyledFirstOffer,
  StyledIcon,
  StyledOfferTemplatesText,
  StyledStatusLabel,
  StyledTabStrip,
  StyledTooltip,
  StyledViewDetailText,
  TemsEditDetailContainer,
  TermConditionId,
  TermDetailsContainer,
  TermName,
  TermsGridItemBottomFooter,
  TermsStatusContainer,
  TranslationContent,
  TranslationAndButtonContainer,
  Translations,
  TranslationBox,
} from './TermConditionItem.style';
import { getCampaignsForReport } from 'utils/api/campaigns';
import { getOfferImpactsByIds } from 'utils/api/offers';
import { getUsageReportRows } from 'utils/impact';
import { downloadUsageReport } from 'utils/download';
import { UsageEntityType, UsageEntityTypeLabel } from 'app/genericSlices/usage';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';

const TermConditionItem = ({
  term,
  editTermClicked,
  downloadUsageReport,
  archiveTerm,
  unarchiveTerm,
  className,
}: TermConditionItemProps) => {
  const { config, languages } = useSelector(marketConfig);
  const lang = config.primaryLanguage as Language;
  const [selectedTranslation, setSelectedTranslation] = useState<Language>(term?.translationsMap[lang]?.language);

  return (
    <Container className={className} data-automation-id="term-item">
      <HeaderContainer className={className} data-automation-id="header-container" id="headerContainer">
        <Header>
          <TermName title={term.name}>{term.name}</TermName>
          <HeaderActions>
            <>
              {!term.isArchive && (
                <StyledDownloadButton
                  onClick={() => downloadUsageReport(term?.id, config)}
                  data-title="Download Usage Report"
                  data-automation-id="term-download-button"
                >
                  <StyledIcon name="newDownload2" />
                </StyledDownloadButton>
              )}
              <FeatureGuard features={[{ feature: Feature.TermConditionArchive }]}>
                {term.isArchive ? (
                  <StyledButtonText
                    onClick={() => {
                      unarchiveTerm(term);
                    }}
                    data-title="Unarchive"
                  >
                    <StyledArchiveIcon name="newUnarchive" />
                  </StyledButtonText>
                ) : (
                  <StyledButtonText
                    onClick={() => {
                      archiveTerm(term);
                    }}
                    data-title="Archive"
                  >
                    <StyledArchiveIcon name="newArchive" />
                  </StyledButtonText>
                )}
              </FeatureGuard>
            </>
          </HeaderActions>
        </Header>
      </HeaderContainer>
      <TermDetailsContainer>
        <TermConditionId>ID {term?.id.toString()}</TermConditionId>
        <OfferTemplates>
          <StyledOfferTemplatesText>Offer Template:&nbsp;</StyledOfferTemplatesText>
          {term?.template?.length && <StyledFirstOffer>{OfferTemplatesTypes[term?.template[0]].name}</StyledFirstOffer>}
          {term?.template?.length > 1 && (
            <>
              <div data-for={term?.id} data-tip data-automation-id="additional-templates-count">
                +{term?.template.length - 1}
              </div>
              <StyledTooltip
                id={term?.id.toString()}
                place="bottom"
                content={`${term?.template
                  .slice(1)
                  .map((t) => OfferTemplatesTypes[t].name)
                  .join(', ')}`}
                onHover
              />
            </>
          )}
        </OfferTemplates>
      </TermDetailsContainer>
      <TranslationAndButtonContainer>
        <Translations>
          <StyledTabStrip
            tabs={languages.map((language: Language) => ({
              id: term?.translationsMap[language] && term?.translationsMap[language].language,
              title: LanguageRecord[language],
            }))}
            selectedTabId={selectedTranslation}
            onClick={(tabId: Language) => setSelectedTranslation(tabId)}
          />
          <TranslationBox>
            <TranslationContent data-automation-id="term-content">
              {term?.translationsMap[selectedTranslation]?.content}
            </TranslationContent>
          </TranslationBox>
        </Translations>
        <TermsGridItemBottomFooter>
          <TermsStatusContainer>
            <FeatureGuard features={[{ feature: Feature.TermConditionArchive }]}>
              {term.isArchive && <NewStyledStatusLabel type={ApprovalStatus.Archived} />}
            </FeatureGuard>
          </TermsStatusContainer>
          {!term.isArchive && (
            <TemsEditDetailContainer>
              <StyledViewDetailText onClick={editTermClicked}>
                Edit
              </StyledViewDetailText>
            </TemsEditDetailContainer>
          )}
        </TermsGridItemBottomFooter>
      </TranslationAndButtonContainer>
    </Container>
  );
};

export default TermConditionItem;
