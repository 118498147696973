import styled from '@emotion/styled';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import { StyledLabelProps } from './DiscountComponent.consts';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { newTypography } from 'styles/typography';
import NewTextField from 'components/shared/newTextField/NewTextField';

export const DiscountComponentContainer = styled.div`
position:relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${newTypography.unit * 2}px;
`;

export const DiscountComponentRow = styled.div`
  display: flex;
  gap: ${newTypography.unit * 2}px;
  margin-top: ${newTypography.unit * 7.3}px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;
export const StyledSelectbox = styled(Selectbox)`
  display: flex;
  width: 100%;
` as unknown as typeof Selectbox;

export const CurrencyContainer = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const StyledTextField = styled(NewTextField)`
  input {
  width:155px;
padding: 8px 0px 8px 0px;
gap: 8px;
border: 0px 0px 1px 0px;
opacity: 0px;
background: none;
outline: none;
font-size: 14px;
font-family: ${newTypography.primaryFont};
-webkit-flex: 1;
-ms-flex: 1;
flex: 1;
    color: #292929;
    height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const MinimumPurchaseTextField = styled(NewTextField)`
    input {
    width:327px;
    padding: 8px 0px 8px 0px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    opacity: 0px;
    border: none;
    background: none;
    outline: none;
    font-size: 14px;
    font-family: ${newTypography.primaryFont};
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #292929;
    height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const MinimumPurchase = styled(Checkbox)`
label{
  font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #292929;
}

`;

export const StyledLabel = styled.label<StyledLabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
  flex: 1 1;
`;
