import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonOutlined, NewButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { ThemeProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { OfferTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import NewOfferAddNewButton from 'pages/shared/addNewButton/OfferAddNewButton';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';

export const OfferPageContainer = styled(NewPageContainer)`
    margin: 0px 80px;
`;
// region Offers
export const ButtonsContainer = styled.div`
    display: flex;
`;

export const ZonesButton = styled(ButtonOutlined)`
width: 36px;
height: 36px;
gap: 0px;
border-radius: 5.02px;
border: 1.26px 0px 0px 0px;
opacity: 0px;
padding: ${typography.unit * 2}px;
border: 1.26px solid #292929;
:hover{
border: 1.26px solid #292929;
}
:focus{
border: 1.26px solid blue;
}
`;
export const OfferPageTitle = styled(OfferTextPageTitle)`
width: 385px;
height: 52px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 56px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
color: #292929;
`;


export const OfferAddNewButton = styled(NewOfferAddNewButton)`
width: 36px;
height: 36px;
gap: 0px;
border-radius: 5.02px;
border: 1.26px 0px 0px 0px;
opacity: 0px;
padding: ${typography.unit * 2}px;
border: 1.26px solid #C08B00;
:hover{
border: 1.26px solid #C08B00;
}
`;


export const ZonesIcon = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 14px;
`;

export const OffersGrid = styled.div`
  overflow: auto;
`;

export const OffersGridContainer = styled(InfiniteScroll)`
  display: flex;
  margin-left: ${typography.unit * 7}px;
  margin-bottom: ${typography.unit * 5}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: ${typography.unit * 9}px;
  row-gap: ${typography.unit * 12}px;
  padding-bottom: ${typography.unit * 5}px;
  word-break: break-all;
  margin-top:10px;
`;
// endregion
export const NewOffersGridContainer = styled(InfiniteScroll)`
  display: flex;
  padding-left: ${newTypography.unit * 18.25}px;;
  margin-right:auto;
  padding-bottom: ${newTypography.unit * 5}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: ${newTypography.unit * 9.5}px;
  row-gap: 38px;
  padding-bottom: ${newTypography.unit * 5}px;
  word-break: break-all;
  padding-top:10px;
`;
// region Offer shared
export const NoImageText = styled.div<StyledWithThemeProps>`
  display: flex;
  align-self: center;
  color: ${newGlobalTheme.colors.toggle.disabled};
  text-align: center;
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${newTypography.unit * 2}px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -50px;
`;
export const TitleActionButtonsContainer = styled.div`
  gap: ${newTypography.unit * 4}px;
`;

export const OfferTitleActionHeader = styled.div`
  gap: ${newTypography.unit * 4}px;
`;
export const OfferFilterHeader = styled.div`
  gap: ${newTypography.unit * 4}px;
`;
export const OfferNewStyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 16.25}px ${typography.unit * 6}px 0 ${typography.unit * 6}px;
  width:94%;
  padding-bottom:30px;
  margin-left: auto;
  margin-right:auto;

`;

export const RestoreDefaultsButton = styled(NewButtonText)`
  display: flex; 
  flex-direction: row; 
  padding: 0px 20px; 
  font-weight: 700;
  justify-content: flex-start; 
  font-size: 14px;
  `

export const OfferFilterRowWrapper = styled.div`
  display: flex; 
  flex-direction: column; 
`

// endregion
