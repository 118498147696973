import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button';
import { Icon } from 'components/shared/icon';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const PLUContainer = styled.div`
  display: flex;
  margin-top: ${typography.unit * 5}px;
  margin-bottom: ${typography.unit * 0}px;
`;

export const PLUListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label<StyledWithThemeProps & { disabled: boolean }>`
  width: 100px;
  height: 15px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-weight: 700;
  line-height: 15.17px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
  margin-top: ${typography.unit * 2}px;
  margin-right: ${typography.unit * 2}px;
`;

export const StyledImagePLUList = styled.ul`
  display: flex;
  font-size: 13px;
  gap: ${typography.unit * 2}px;
  flex-direction: column;
  list-style-type: none;
  margin-top: 0;
  padding: 0;
`;

export const StyledImagePLUListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${typography.unit * 2}px;
  padding: ${typography.unit * 2}px;
  &:first-of-type {
    padding-top: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
`;

export const StyledButtonTextView = styled(ButtonText)`
  align-self: center;
  margin-left: ${typography.unit * 2}px;
  width: 16px;
`;
export const StyledButtonTextAdd = styled(ButtonText)`
  align-self: flex-start;
  margin-left: ${typography.unit * 2}px;
  margin-top: ${typography.unit * 2}px;
  width: 16px;
`;
