import React, { useEffect, useState } from 'react';
import { StyledFilterBarSelectPaginate } from 'pages/shared/shared.style';
import {
  ApprovalStatus,
  ApprovalStatusLabel,
  ApprovalStatusWithLabel,
  GenericEntity,
  OrderDirection,
} from 'utils/types';
import { OfferTemplates, OfferApprovalStatuses } from 'utils/types/offers';
import { useSelector } from 'react-redux';
import { useUrlFilters } from 'hooks/use-url-filters';
import { store } from 'app/store';
import { BaseOfferRec, DoeInitialState, doeOffersPage, initialState, vceOffersPage } from 'app/genericSlices/offers';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { useQuery } from '@apollo/client';
import { LocationSet } from 'utils/types/locations';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { FetchPolicies } from 'utils/types/common';
import {
  NewStyledFilterBarSelect,
 
  OfferFieldLabel,
 
  OfferFilterBarContainer,
 
  OfferFilterRow,
 
  NewOfferFilters,
 
  OfferSearchField,
 
  OfferNewSearchTextField,
  StyledTotalAmount,
  TotalCountOfferFilterRow,
  DoeOfferFilterRow,
} from 'pages/offers/components/offerFilterBar/OfferFilterBar.style';
import { OfferFilters, OfferSource } from '../../Offers.const';
import { useOfferBanksQuery } from 'hooks/offer-banks';
import { useBaseOfferRecsQuery } from 'hooks/base-offer-recs';
import { marketConfig } from 'app/slices/config';
import { OfferFilterRowWrapper, RestoreDefaultsButton } from '../../Offers.style';

export const OfferFilterBar = ({ offerSource, total }:
  {
    offerSource: OfferSource,
    total: number,
 }) => {

  const offersPage = offerSource === OfferSource.VCE ? vceOffersPage : doeOffersPage;
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Offer]);
  const { filters } = useSelector(offersPage.offersState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[OfferFilters.SearchQuery]);
  const { loadOptions: loadOfferBanks } = useOfferBanksQuery(true);
  const { loadOptions: loadOfferGroup } = useBaseOfferRecsQuery(true, filters?.[OfferFilters.OfferBank]);
  const [offerBankOpen, setOfferBankOpen] = useState(false);
  const [offerGroupKey, setOfferGroupKey] = useState(false);
  const [offerGroupOpen, setOfferGroupOpen] = useState(false);
  useEffect(() => {
    setOfferGroupKey((prev) => !prev);
  }, [offerGroupOpen]);

  const filteredStatuses =
    offerSource === OfferSource.DOE ? [ApprovalStatus.Approved, ApprovalStatus.Archived] : OfferApprovalStatuses;
  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: offerSource === OfferSource.DOE,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );

  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      offersPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
    loadTags();
  }, []);


  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => (offerSource === OfferSource.VCE && enableDigitalDownload) || template.id !== '14',
  );

  // On restore defaults button click, reset to default filters
  const restoreDefaultFilters = () => {
    if (offerSource === OfferSource.VCE) {
      urlFilters.filterWithClear(
        OfferFilters.Status,
        initialState.filters[OfferFilters.Status]
      );
    }
    else {
      urlFilters.filterWithClear(
        OfferFilters.Status,
        DoeInitialState.filters[OfferFilters.Status]
      );
    }
  }

  return (
    <OfferFilterBarContainer tabIndex={-1}>
    <OfferFilterRowWrapper>
      <NewOfferFilters tabIndex={-1}>
        <OfferFilterRow tabIndex={-1}>
          <OfferFieldLabel tabIndex={0}>Template</OfferFieldLabel>
          <NewStyledFilterBarSelect
            placeholder="Select"
            name="template"
            multiple
            items={Object.values(OfferTemplates) as any[]}
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.TemplateType,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={filters[OfferFilters.TemplateType]}
            reset
            maxItems={1}
            selectWidth={225}
            filters={filters}
            version='offer-filter'
          />
        </OfferFilterRow>

        <OfferFilterRow tabIndex={-1}>
          <OfferFieldLabel tabIndex={0}>Status</OfferFieldLabel>
          <NewStyledFilterBarSelect
            placeholder="Select"
            name="status"
            multiple
            items={
              filteredStatuses.map((status) => ({
                id: status,
                name: ApprovalStatusLabel[status as ApprovalStatusWithLabel],
              })) as any[]
            }
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.Status,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={filters[OfferFilters.Status]}
            reset
            limit={offerSource === OfferSource.DOE ? 2 : 5}
            maxItems={1}
            selectWidth={225}
            filters={filters}
            version='offer-filter'
          />
        </OfferFilterRow>

        <OfferFilterRow tabIndex={-1}>
          <OfferFieldLabel tabIndex={0}>Tags</OfferFieldLabel>
          <NewStyledFilterBarSelect
            key={`${Boolean(tags.length)}`}
            placeholder="Select"
            name="tags"
            multiple
            maxItems={1}
            items={tags}
            onChange={(selectedItems: any) => {
              urlFilters.filter(
                OfferFilters.Tags,
                Object.values(selectedItems).map((i: any) => i.id),
              );
            }}
            initialSelectedItems={tags?.length ? filters[OfferFilters.Tags] : []}
            reset
            withAmount
            withSearch
            selectWidth={225}
            filters={filters}
            version='offer-filter'
          />
        </OfferFilterRow>
        {offerSource === OfferSource.VCE && (
          <MarketConfigurationGuard
            configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
          >
            <OfferFilterRow tabIndex={-1}>
              <OfferFieldLabel tabIndex={0}>Zone</OfferFieldLabel>
              <NewStyledFilterBarSelect
                key={`${JSON.stringify(zonesData?.getLocationSets?.items)}`}
                placeholder="Select"
                name="zone"
                multiple
                items={zonesData?.getLocationSets?.items}
                onChange={(selectedItems: any) => {
                  urlFilters.filter(
                    OfferFilters.Zone,
                    Object.values(selectedItems).map((i: any) => i.id),
                  );
                }}
                initialSelectedItems={zonesData?.getLocationSets?.total ? filters[OfferFilters.Zone] : []}
                reset
                withSearch
                maxItems={1}
                selectWidth={225}
                filters={filters}
                version='offer-filter'
              />
            </OfferFilterRow>
          </MarketConfigurationGuard>
        )}
        {offerSource === OfferSource.DOE && (
          <>
            <DoeOfferFilterRow tabIndex={-1}>
              <StyledFilterBarSelectPaginate
                placeholder="Select"
                name="offerBank"
                label="Offer Bank"
                multiple
                onChange={(selectedItems: { id: string; name: string }[]) => {
                  urlFilters.filter(
                    OfferFilters.OfferBank,
                    selectedItems.map((i) => i.id),
                  );
                }}
                initialSelectedItems={
                  filters?.[OfferFilters.OfferBank]?.length > 0 ? filters[OfferFilters.OfferBank] : []
                }
                reset
                maxItems={1}
                isOpen={offerBankOpen}
                setIsOpen={setOfferBankOpen}
                loadOptions={loadOfferBanks}
                selectWidth={225}
                filters={filters}
            version='offer-filter'
          />
            </DoeOfferFilterRow>
            <DoeOfferFilterRow tabIndex={-1}>
              <StyledFilterBarSelectPaginate
                key={`offerFilter_${offerGroupKey}`}
                placeholder="Select"
                name="offerGroup"
                label="Offer Group"
                multiple
                onChange={(selectedItems: BaseOfferRec[]) => {
                  urlFilters.filter(
                    OfferFilters.OfferGroup,
                    selectedItems.map((i) => i.id),
                  );
                }}
                disabled={!(filters[OfferFilters.OfferBank] && filters[OfferFilters.OfferBank].length > 0)}
                loadOptions={loadOfferGroup}
                initialSelectedItems={
                  filters?.[OfferFilters.OfferGroup]?.length > 0 ? filters[OfferFilters.OfferGroup] : []
                }
                reset
                isOpen={offerGroupOpen}
                setIsOpen={setOfferGroupOpen}
                maxItems={1}
                selectWidth={225}
                filters={filters}
            version='offer-filter'
          />
            </DoeOfferFilterRow>
          </>
        )}
        <OfferFilterRow tabIndex={-1}>
          <OfferSearchField tabIndex={-1}></OfferSearchField>
          <OfferNewSearchTextField
            key={`${JSON.stringify(filters[OfferFilters.SearchQuery])}_SearchQuery`}
            name="offers-search"
            caretPosition={caretPosition}
            value={filters[OfferFilters.SearchQuery] ?? ''}
            onChange={(e) => {
              urlFilters.filter(OfferFilters.SearchQuery, e.target.value);
              setCaretPosition(e);
            }}
          />
        </OfferFilterRow>
        <RestoreDefaultsButton onClick={restoreDefaultFilters}>
            Restore Defaults
      </RestoreDefaultsButton>
      </NewOfferFilters>
      </OfferFilterRowWrapper>
      <TotalCountOfferFilterRow>
      <StyledTotalAmount amount={total} aria-label="Total Offer {total}" tabIndex={0} />
      </TotalCountOfferFilterRow>
    </OfferFilterBarContainer>
  );
};
