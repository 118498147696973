import React, { useEffect } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import {
  EnterValueWrapper,
  ProductPicker,
  StyledBuySelectbox,
  StyledForSelectbox,
  StyledLabel,
  StyledLightLabel,
} from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import { getIsControlWithError } from 'utils/form';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';

export const MultiItemDiscount = ({
  disabled,
  onProductSelection,
  offerSource,
  mode,
  offerID,
  onProductPreviewClick,
}: TemplateProps) => {
  const {
    control,
    register,
    getValues,
    watch,
    formState: { errors },
    trigger,
    clearErrors,
    setValue,
    resetField,
  } = useFormContext();

  const { isPercent, isFree, shouldDisplayCurrency } = useDiscountType();
  const [discountCondition] = watch(['versions.0.templateValues.discountCondition']);
  const { config } = useSelector(marketConfig);

  const discountValueName = 'versions.0.templateValues.discountValue';
  const templateValuesDiscountConditionPath = 'versions.0.templateValues.discountCondition';
  const isDoeEditMode = offerSource === OfferSource.DOE && mode === 'edit';

  const [buyProducts, discountType, buy, discountValue] = watch([
    'versions.0.templateValues.buyProducts',
    'versions.0.templateValues.discountType',
    'versions.0.templateValues.buy',
    discountValueName,
  ]);

  useEffect(() => {
    if (!isFree) {
      setValue(templateValuesDiscountConditionPath, DiscountCondition.None);
    } else {
      resetField(templateValuesDiscountConditionPath, {
        defaultValue:
          discountCondition === DiscountCondition.None ? DiscountCondition.MinimumPurchase : discountCondition,
      });
    }
  }, [isFree]);

  const handleChange = () => {
    clearErrors(discountValueName);
    if (discountValue) {
      trigger(discountValueName);
    }
  };

  return (
    <OfferInfoWrapper disabled={disabled}>
      <LineWrapper gap={8}>
        <StyledLabel>Buy</StyledLabel>
        <StyledBuySelectbox
          control={control}
          name="versions.0.templateValues.buy"
          disabled={disabled || isDoeEditMode}
          items={Array.from(
            { length: 15 },
            (x, i) =>
              ({
                id: (i + 1).toString(),
                name: (i + 1).toString(),
              } as any),
          )}
          defaultValue="1"
          initialSelectedItems={buy ? [typeof buy === 'object' ? buy.id : buy] : ['1']}
          onChange={handleChange}
          selectWidth={isFree ? 120 : 164}
          version="offer-form"
        />
        <ProductPicker>
          <ProductLine>{calcProductsHint(buyProducts)}</ProductLine>
          {getProductsAction(
            disabled,
            buyProducts,
            getValues,
            'Select Products',
            'buyProducts',
            (data: any) => ({
              buyProducts: data,
            }),
            onProductSelection,
            null,
            null,
            null,
            offerID,
            onProductPreviewClick,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProducts', {
              value: buyProducts,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </LineWrapper>
      <LineWrapper gap={8}>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled || isDoeEditMode}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={isFree ? 120 : 164}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
          onChange={handleChange}
          version="offer-form"
        />
        {!isFree && (
          <>
            <EnterValueWrapper
              width={136}
              disabled={disabled}
              errors={getIsControlWithError('versions.0.templateValues.discountValue', errors)}
            >
              {shouldDisplayCurrency && <Currency />}
              <OfferTemplateTextField
                disabled={disabled || isDoeEditMode}
                register={register}
                validation={{
                  required: true,
                  min: isPercent ? 1 : 0.01,
                  max: isPercent ? 99 : null,
                  pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                  validate: (v: string) => {
                    const buy = watch('versions.0.templateValues.buy');
                    const isNonExactPenny = Math.ceil(+(Number(v) * 100).toFixed(2)) % Number(buy) !== 0;
                    if (discountType === DiscountType.MoneyOff && isNonExactPenny) {
                      return `${config.currency} off divided by number of items does not result in exact penny value for selected option`;
                    }
                    if (discountType === DiscountType.FixedPrice && isNonExactPenny) {
                      return 'Fixed Price total divided by number of items does not result in exact penny value for selected option';
                    }
                    return (
                      (!Number.isNaN(Number(v)) && Number(v) >= 0.01) ||
                      (isPercent && Number(v) < 100 && Number(v) >= 1)
                    );
                  },
                }}
                errors={errors}
                name="versions.0.templateValues.discountValue"
                placeholder="Enter value"
                showError={false}
                selectWidth={120}
              />
            </EnterValueWrapper>
          </>
        )}
        {isFree ? (
          <>
            <StyledLightLabel>with</StyledLightLabel>
            <StyledForSelectbox
              name="versions.0.templateValues.discountCondition"
              control={control}
              disabled={disabled || isDoeEditMode}
              validation={{
                required: true,
              }}
              items={
                Object.entries(DiscountConditionRecord)
                  .filter(([key]) => key !== DiscountCondition.None && key !== DiscountCondition.WithPurchaseOf)
                  .map(([key, value]) => {
                    return {
                      id: key,
                      name: value,
                    };
                  }) as any[]
              }
              defaultValue={DiscountCondition.MinimumPurchase}
              initialSelectedItems={
                discountCondition && discountCondition !== DiscountCondition.None
                  ? [discountCondition]
                  : [DiscountCondition.MinimumPurchase]
              }
              selectWidth={150}
              version="offer-form"
            />
            {discountCondition === DiscountCondition.MinimumPurchase && (
              <>
                <StyledLightLabel>of</StyledLightLabel>
                <EnterValueWrapper
                  width={136}
                  disabled={disabled}
                  errors={getIsControlWithError(`versions.0.templateValues.conditionValue`, errors)}
                >
                  <Currency />
                  <OfferTemplateTextField
                    disabled={disabled || isDoeEditMode}
                    register={register}
                    validation={{
                      required: true,
                      min: 0.01,
                      max: null,
                      pattern: /^\d+(?:\.\d{1,2})?$/,
                      validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                    }}
                    errors={errors}
                    name="versions.0.templateValues.conditionValue"
                    placeholder="Enter value"
                    selectWidth={120}
                  />
                </EnterValueWrapper>
              </>
            )}
          </>
        ) : null}
      </LineWrapper>
    </OfferInfoWrapper>
  );
};
