import React, { useState } from 'react';
import {  StyledLoader } from 'pages/shared/shared.style';
import { tabName } from 'components/header/Header.consts';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { UserRole } from 'utils/types/users';
import { ActionButtonsContainer, ImageNewStyledHeader, ImagePageContainer, ImagesList, ImagesListWrapper, StyledInputFiles } from '../images/Images.style';
import ImagesFilterBar from '../images/components/imagesFilterBar/ImagesFilterBar';
import { openModal, Modals } from 'app/slices/modals';
import { store } from 'app/store';
import { archiveImageClicked, readDoeImage, unarchiveImageClicked } from '../images/images.utils';
import { useSelector } from 'react-redux';
import { ImageProps, ImageSource } from '../images/Images.consts';
import { doeImagesPage } from 'app/genericSlices/images';
import { useQuery } from '@apollo/client';
import useQueryInterval from 'hooks/use-query-polling';
import { useToastError } from 'hooks/use-toast-error';
import { FormMode, OrderDirection } from 'utils/types';
import { FetchPolicies } from 'utils/types/common';
import { doeImagesGqls } from './DoeImages.gqls';
import { Loader } from 'components/shared/loader';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import ImageItem from '../images/components/imageItem/ImageItem';
import { getDoeImageById } from 'utils/api/images';
import { DoeImage } from 'utils/types/images';
import AddIcon from '@mui/icons-material/Add';

const imageButton = {
  VIEW: 'View',
  EDIT: 'Edit',
};

const DoeImages = () => {
  const { filters } = useSelector(doeImagesPage.imagesState);
  const [isImageArchieving, setIsImageArchieving] = useState(false);
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(doeImagesGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: doeImagesPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
        imageSource: ImageSource.DOE,
      },
    },
  });
  const {
    getImages: { items: images, total },
  } = data || { getImages: { items: undefined } };
  useToastError(error, 'Error loading images');
  useQueryInterval(600000, startPolling, stopPolling);

  const handleFiles = async (files: FileList) => {
    const uploadedImage = await readDoeImage(files[0]);
    if (uploadedImage) {
      store.dispatch(
        openModal({ modal: Modals.DoeImagesModal, props: { mode: FormMode.New, image: { ...uploadedImage } } }),
      );
    }
  };

  const imageButtonClicked = async (id: number, imageButtonVal: string) => {
    const { data: doeImageData } = await getDoeImageById(Number(id));
    const imageData: DoeImage = { ...doeImageData.getDoeImage } as any;
    store.dispatch(
      openModal({
        modal: Modals.DoeImagesModal,
        props: { image: imageData, mode: imageButtonVal === imageButton.VIEW ? FormMode.View : FormMode.Edit },
      }),
    );
  };

  const fetchNextImages = async () => {
    if (images.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: doeImagesPage.getFilters(filters),
            offset: images.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
            imageSource: ImageSource.DOE,
          },
        },
      });
    }
  };

  return (
       <ImagePageContainer>
      <ImageNewStyledHeader data-automation-id="header">
        <NewTextPageTitle text={tabName.DoeImages} />
        <ActionButtonsContainer data-automation-id="actions">     
          <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
            <div data-title="Add">
            <StyledInputFiles handleFiles={handleFiles} filesType="image/*" data-title="Add New" multiple={false}>
                <AddIcon style={{ fill: 'black' }} data-automation-id="newPlus" />
            </StyledInputFiles>
            </div>
          </RoleGuard>
        </ActionButtonsContainer>
        <ImagesFilterBar imageSource={ImageSource.DOE} total={total}/>
      </ImageNewStyledHeader>
      {!images ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          {Boolean(images.length) && (
            <ImagesListWrapper id="images-scroll" data-testid="images-scroll">
              {isImageArchieving && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              <ImagesList
                scrollableTarget="images-scroll"
                dataLength={images.length}
                next={fetchNextImages}
                hasMore={images.length < total}
                loader={<StyledLoader size={LoaderSize.Large} />}
              >
                {images.map((image: ImageProps, index: number) => (
                  <ImageItem
                    key={`${image.id}_${index}`}
                    image={image}
                    viewImage={() => imageButtonClicked(image.id, imageButton.VIEW)}
                    editImage={() => imageButtonClicked(image.id, imageButton.EDIT)}
                    archiveImage={(image1) => archiveImageClicked(image1, setIsImageArchieving)}
                    unarchiveImage={unarchiveImageClicked}
                  />
                ))}
              </ImagesList>
              <Tooltip id={'image-lock-icon'} onHover content="Locked for changes" place="bottom" />
            </ImagesListWrapper>
          )}
        </>
      )}
    </ImagePageContainer>
  );
};

export default DoeImages;
